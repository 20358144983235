import React from 'react';
import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='payments' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Pay for your Service</h2>
          <p>
            Click the service you want to access. Our agents will then proceed with the steps needed to solve the challenges you encountering.
            Please enter your name and email address on the payment form to allow our agents to confirm your payment.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
       <div className ="row">
            <p>
                All service troubleshooting sessions is a maximum of 30 minutes except for the following: Backup and Data Restore, Software Training Service and Secure device from viruses and malware which are 45 minutes. 
            </p>
       </div>
      </div>
    </div>
  )
}
