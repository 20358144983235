import React from 'react';
import  { useState } from 'react';
import StripeContainer from './stripeContainer';

export const Payments = (props) => {

    const [showItem, setShowItem] = useState(false);
    
    return (
      <div id='payments' class ="text-center">
        <div className='container'>
          <div className='section-title text-center'>
            <h2>Our Service Fees</h2>
          </div>
         
        </div>
        <div className="row">
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className="portfolio-item">
                  <h1>Smart Device troubleshooting</h1>
                        {showItem ? (
                        <StripeContainer />
                        ) : (
                        <>
                            <h3>$5.00</h3>
                            <button onClick={() => setShowItem(true)}>Purchase Service Time</button>
                        </>
                        )}     
                </div>
                              
            </div>
          </div>
        </div>
        <div className="row">
          <div className='portfolio-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                    <h1>Mobile Device Updating</h1>
                          {showItem ? (
                          <StripeContainer />
                          ) : (
                          <>
                              <h3>$21.00</h3>
                              <button onClick={() => setShowItem(true)}>Purchase Service Time</button>
                          </>
                          )}    
                </div>
            </div>
        </div>
        <div className="row">
          <div className='portfolio-items'>
                <div className='portfolio-item'>
                    <a href ="https://buy.stripe.com/test_cN229D14a07i13idQR">Pay Device clean up</a> 
                </div>
                <div className='portfolio-item'>
                    <a href ="https://buy.stripe.com/test_5kA01v0067zKeU8fZ0">Pay Device clean up</a> 
                </div>
                <div className='col-sm-6 col-md-4 col-lg-4'>
                  <h1><a href ="https://buy.stripe.com/test_cN229D14a07i13idQR">Pay Device clean up</a></h1>
                </div>
          </div>
           
        </div>

      </div>
    )
  } 

                  
              