import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import  LearnPage  from "./components/learn";
import  Home  from "./components/home";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Payments } from "./components/payment/paymentsection";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import ChatwootWidget from "./components/chatwoot.js";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "./App.css";

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDRYmzSLwzExDT_GEQqNEuqv4nIkQ5pt88",
  authDomain: "serviceprodigy-306aa.firebaseapp.com",
  projectId: "serviceprodigy-306aa",
  storageBucket: "serviceprodigy-306aa.appspot.com",
  messagingSenderId: "360529554686",
  appId: "1:360529554686:web:ed2a1bd0a7b737eb6489af",
  measurementId: "G-V82XJWZXHS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn" element={<LearnPage/>} />
        </Routes>
    </Router>
    
  );
};

export default App;
