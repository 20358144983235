import React from 'react';
import { Zoom  } from 'react-slideshow-image';
import { Navigation } from "./navigation2";
import 'react-slideshow-image/dist/styles.css'


  const homePage = {
    border: '5px solid',
    margin: 'auto',
    width: '50%',
    padding: '10px',
  }

  const divS1 = {
        position: 'relative',
        overflow: 'hidden',
        margin: '25px 0px 40px',
        borderRadius: '4px',
        background: 'rgb(255, 255, 255)',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        width: '700px',
        height: '1000px',
        justifyContent: 'center',
        alignItems: 'center'
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '800px',
    width: '600px'
  }
  const slideImages = [
    {
      url: 'img/images/poster2.png',
      link : 'https://buy.stripe.com/aEU7wodefdR99So28g'
    },
     {
      url: 'img/images/poster4.png',
      link : 'https://buy.stripe.com/fZe5ogcab14ne8E9AH'
    },
  ];



const Slideshow = () => {
  return (
    <div style={divS1}>
        <div className="slide-container">
        <Zoom scale={0.1} indicators={true}>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <a href={slideImage.link} target="_blank">
                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                </div>
              </a> 
            </div>
          ))} 
        </Zoom>
      </div>
    </div>
    
  );
}

const Instructions = () => {
  return (
    <div className="instructions">
      <h2>Instructions:</h2>
      <p>Please select the training program you wish to enroll in. Upon selection, you will be redirected to the payment portal.</p>
      <p> Following successful payment processing, you will receive a confirmation email containing detailed course information, a starter setup pack, and the course link.</p>
      
    </div>
  );
}

const LearnPage = () => {

  return (
    <div style={homePage}>
    <Navigation />
 
      <header>
        <h1>Learning</h1>
      </header>
      
      <Slideshow />
      <Instructions />
    </div>
  );
}

export default LearnPage;